<!--
 * @Author: zhangguoliang
 * @Date: 2021-11-22 17:12:10
 * @LastEditors: wuxinxin
 * @LastEditTime: 2022-04-18 14:21:22
-->
<template>
  <div class="wrap">
    <div class="content-wrap">
      <img class="wrap-background" src="@/assets/img/appointment.png" />
    </div>
    <div class="filed">
      <div class="contacts">
        <div class="filed-hearer"></div>
        <div class="info">
          <div class="items">
            <label>联系人</label>
            <van-field
              v-model="realName"
              class="contact"
              maxlength="10"
              :error="false"
              :rules="[{ required: true, message: '' }]"
            />
          </div>
          <div class="items">
            <label>联系电话</label>
            <van-field
              v-model="mobile"
              class="contact"
              type="tel"
              maxlength="11"
              :error="false"
              :rules="[{ required: true, message: '' }]"
            />
          </div>
          <div class="items codeMsg">
            <label>验证码</label>
            <van-field
              v-model="mobileVerifyCode"
              class="contact"
              maxlength="6"
              type="tel"
            />
            <button
              :class="[codeDisabled ? 'code' : 'code-enabled']"
              @click="start"
              type="text"
              :disabled="codeDisabled"
            >
              {{ codeMsg }}
            </button>
          </div>
          <div class="items">
            <label>预约说明</label>
            <van-field
              v-model="applyExplain"
              class="contact"
              type="textarea"
              maxlength="100"
            />
          </div>
        </div>
        <div class="footer">
          <van-button
            type="info"
            native-type="submit"
            class="apply-submit"
            @click="onSubmit"
            >提交申请</van-button
          >
          <div class="line-text">预约热线:400-005-8008</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Notify } from 'vant';
import 'vant/lib/button/style';
import * as Api from '@/api/tryOut.ts';
import VConsole from 'vconsole';
export default Vue.extend({
  data() {
    return {
      show: false,
      realName: '',
      mobile: '',
      mobileVerifyCode: '',
      applyExplain: '',
      codeDisabled: true,
      codeMsg: '获取验证码',
      countdown: 60,
      timer: null,
    };
  },
  created() {
    document.title = '预约申请';
  },
  //监听
  watch: {
    mobile: {
      handler(newName) {
        var mobile = /^[1][3,4,5,7,8,9][0-9]{9}$/.test(newName);
        if (mobile === false && mobile !== '') {
          this.codeDisabled = true;
        } else {
          this.codeDisabled = false;
        }
      },
    },
  },
  methods: {
    /**
     * @description: 获取h5执行时所在环境 0 web浏览器 1 抖音 2 微信
     * @param {*}
     * @return {number}
     */
    channel() {
      const ua = window.navigator.userAgent.toLowerCase();
      if (/MicroMessenger/i.test(ua)) {
        return 2;
      }
      if (/aweme/i.test(ua)) {
        return 1;
      }
      return 3;
    },
    onSubmit() {
      if (
        !this.realName ||
        /^[a-zA-Z\u4e00-\u9fa5]{2,10}$/.test(this.realName) === false
      ) {
        Notify(' 请输入2-10位字母或汉字');
        return;
      }
      if (!this.mobile || !/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.mobile)) {
        Notify('请输入正确手机号');
        return;
      }
      if (this.mobileVerifyCode === '') {
        Notify('请输入验证码');
      } else {
        const param = {
          mobile: this.mobile,
          realName: this.realName,
          mobileVerifyCode: this.mobileVerifyCode,
          applyExplain: this.applyExplain,
          oauthClientId: 'SUB_YUNSUANFANG',
          oauthClientApplicationName: '云算房',
          intentionSource: this.channel(),
        };
        Api.agenciesIntentionClient(param).then((res) => {
          if (res) {
            this.$message.success('提交成功');
            this.reset();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    reset() {
      this.mobile = '';
      this.realName = '';
      this.mobileVerifyCode = '';
      this.applyExplain = '';
      clearInterval(this.timer);
      this.codeMsg = '获取验证码';
      this.countdown = 60;
      this.timer = null;
      this.codeDisabled = false;
    },
    start() {
      if (this.mobile !== '') {
        Api.mobile_smsCode(this.mobile).then((res) => {
          if (res) {
            this.codeDisabled = true;
            if (!this.timer) {
              this.timer = setInterval(() => {
                if (this.countdown > 0 && this.countdown <= 60) {
                  this.countdown--;
                  if (this.countdown !== 0) {
                    this.codeDisabled = true;
                    this.codeMsg = '重新发送(' + this.countdown + ')';
                  } else {
                    clearInterval(this.timer);
                    this.codeMsg = '获取验证码';
                    this.countdown = 60;
                    this.timer = null;
                    this.codeDisabled = false;
                  }
                }
              }, 1000);
            }
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.codeDisabled = false;
      }
    },
  },
});
</script>
<style lang="less" scoped>
label {
  font-size: 18px;
  color: #1d1d1d;
  font-weight: 800;
  display: block;
  margin-bottom: 5px;
}
.wrap {
  width: 100%;
  .content-wrap {
    overflow-y: auto;
    position: relative;
    .wrap-background {
      width: 100%;
    }
  }
  .filed {
    background-color: #b7d4ff;
    padding: 1px 15px 15px;
    margin-top: -1px;
    .items {
      margin-bottom: 10px;
    }
    .contacts {
      border-radius: 7px;
      background-color: #ffffff;
      margin-top: 20px;
    }
    .info {
      padding: 10px 20px 20px;
      .contact {
        border: 1px solid #abd2ff;
        border-radius: 7px;
        width: 100%;
      }
    }
    .filed-hearer {
      width: 100%;
      height: 72px;
      background-repeat: no-repeat;
      background-image: url(../assets/img/appointment-hearer.png);
      background-size: cover;
    }
    .footer {
      text-align: center;
      padding: 10px 0px 15px;
      .apply-submit {
        height: 56px;
        width: 226px;
        background: linear-gradient(132deg, #f87a27 0%, #fcad37 100%);
        box-shadow: 0px 5px 8px rgba(204, 121, 26, 0.6);
        border: 1px solid rgba(204, 121, 26, 0.01);
        border-radius: 34px;
        font-size: 21px;
        font-weight: 400;
        bottom: 10px;
      }
      .line-text {
        font-size: 18px;
      }
    }
  }
}
.codeMsg {
  position: relative;
  .code {
    position: absolute;
    right: 2%;
    bottom: 1px;
    display: inline-block;
    font-size: 15px;
    top: 37px;
    color: #bcbcbc;
    background-color: #b8b9b900;
    border: 1px rgba(245, 243, 243, 0);
  }
  .code-enabled {
    position: absolute;
    right: 2%;
    bottom: 1px;
    display: inline-block;
    font-size: 15px;
    top: 37px;
    background-color: #b8b9b900;
    border: 1px rgba(245, 243, 243, 0);
    color: #006cff;
  }
}
</style>
<style>
.van-field__control {
  font-size: 18px;
  color: #1d1d1d;
}
.van-cell {
  line-height: 30px;
  padding: 2px 10px;
}
</style>
